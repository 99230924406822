import { Link } from 'gatsby';
import React from 'react';

function NotFoundPage(): JSX.Element {
  return (
    <section className="py-8 py-md-10">
      <div className="container">

        <div className="row">
          <div className="col-12">
            <p>Похоже что-то пошло не так...</p>
            <Link to="/" className="text-decoration-none">Домой</Link>
          </div>
        </div>

      </div>
    </section>
  );
}

export default NotFoundPage;
